<template>
  <div id="points_mall">
    <van-sticky class="search">
      <van-search
        v-model="searchValue"
        shape="round"
        background="#FFC016"
        placeholder="请输入名称、货号、规格、品牌搜索"
        @blur="goodsBlur"
      >
        <template #left-icon>
          <svg-icon icon-class="home_nav_search_icon" />
        </template>
        <template #left>
          <van-icon
            name="arrow-left"
            color="#fff"
            size="18"
            @click="$router.go(-1)"
          />
        </template>
      </van-search>
    </van-sticky>
    <div class="points_title">
      <div
        class="points_order"
        @click="$router.replace({ name: 'integral-order' })"
      >
        <span>积分订单</span>
        <svg-icon icon-class="arrow_right" />
      </div>
    </div>
    <div class="points_content">
      <div class="total_points">
        <div class="left">
          <p>联盟积分会员合计</p>
          <h2>
            {{ integralSum }}
            <img src="@/assets/images/jf_icon.png" width="20" />
          </h2>
        </div>
        <van-button round type="default" url="#/pointsMall/memberPoints"
          >会员积分</van-button
        >
      </div>
      <van-sticky class="options" offset-top="50">
        <van-tabs background="#f6f7fb" @click="selectionSort">
          <van-tab
            v-for="item in classification"
            :title="item.categoryName"
            :key="item.categoryId"
          />
        </van-tabs>
        <van-dropdown-menu active-color="#1D4B89">
          <button
            :style="sortColumn === 'retailPrice' ? 'color:#1D4B89' : ''"
            @click="switchCondition('retailPrice')"
          >
            积分值
            <span>
              <svg-icon
                icon-class="home_more_icon1"
                :style="
                  order && sortColumn === 'retailPrice' ? 'color:#666666' : ''
                "
              />
              <svg-icon
                icon-class="home_more_icon1"
                :style="
                  order && sortColumn === 'retailPrice' ? '' : 'color:#666666'
                "
              />
            </span>
          </button>
          <button
            :style="sortColumn === 'sort' ? 'color:#1D4B89' : ''"
            @click="switchCondition('sort')"
          >
            综合排序
          </button>
          <van-dropdown-item
            @change="integralSelection"
            v-model="integralValue"
            :options="integralOption"
          >
            <template #default></template>
          </van-dropdown-item>
        </van-dropdown-menu>
      </van-sticky>
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="goodsList.length ? '没有更多了' : ''"
        @load="onLoad"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
      >
        <div id="goods_list">
          <p
            class="none_goods_tips"
            v-show="!goodsList.length && !loading && !error"
          >
            暂无相关商品，换个条件试试吧！
          </p>
          <div class="goods_item_box" v-for="list in goodsList" :key="list.id">
            <div class="img_box">
              <img v-lazy="list.filePath" class="goods_img" />
            </div>
            <div class="goods_details">
              <p class="name">{{ list.commodityName }}</p>
              <p class="integral">
                <img src="@/assets/images/jf_icon.png" width="20" />{{
                  userType === "PURCHASE" ? list.tradePrice : list.retailPrice
                }}积分
              </p>
              <van-button
                round
                block
                color="linear-gradient(180deg, #F91C12 0%, #FB4E10 100%)"
                @click="
                  $router.push({
                    path: 'pointsMall/integralGoods',
                    query: {
                      integralGoodsId: list.id,
                    },
                  })
                "
                >立即兑换</van-button
              >
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { preferential } from "@/utils/validate";
export default {
  name: "points-mall",
  computed: {
    ...mapState("user", ["integralSum", "userType", "maxIntegralBank"]),
  },
  data() {
    return {
      oldSearchValue: "",
      searchValue: "",
      integralValue: 0,
      integralOption: [
        { text: "全部商品", value: 0, max: 99999999999, min: 0 },
        { text: "我能兑换", value: 1, max: 0, min: 0 },
        { text: "1~1000", value: 2, max: 1000, min: 1 },
        { text: "1001~5000", value: 3, max: 5000, min: 1001 },
        { text: "5001~10000", value: 4, max: 10000, min: 5001 },
        { text: "10000以上", value: 5, max: 99999999999, min: 10000 },
      ],
      classification: [
        {
          categoryName: "全部",
          select: true,
        },
      ],
      ceCategoryId: [],
      order: false, // desc/asc
      sortColumn: "sort", //排序字段 retailPrice积分值
      goodsList: [],
      loading: false,
      finished: false,
      refresh: false,
      error: false,
      pageNum: 1,
    };
  },
  mounted() {
    this.integralOption[1].max = this.maxIntegralBank.integral;
    // this.getGoodsList();
    this.queryCategoriesCatalog();
  },
  methods: {
    async queryCategoriesCatalog() {
      const data = await this.$API.user.queryCategoriesCatalog({
        object: {
          enterpriseId: "",
        },
      });
      data.data.data.categoriesCatalog.forEach((e) => {
        this.$set(e, "select", false);
        this.classification.push(e);
      });
    },
    selectionSort(i) {
      if (this.classification[i].select) return;
      this.classification.forEach((e) => {
        e.select = false;
      });
      this.classification[i].select = true;
      this.ceCategoryId = [];
      const that = this;
      getId(this.classification[i]);
      function getId(item) {
        if (item.categoryId) that.ceCategoryId.push(item.categoryId);
        if (item.subItemList)
          item.subItemList.forEach((e) => {
            getId(e);
          });
      }

      this.refresh = true;
      this.onLoad();
    },
    async onLoad() {
      // 异步更新数据

      if (this.refresh) {
        this.goodsList = [];
        this.loading = true;
        this.finished = false;
        this.pageNum = 1;
        this.refresh = false;
      }

      let obj = {
        barcode: "",
        brandName: "",
        commodityName: this.searchValue,
        enableState: true,
        order:
          this.sortColumn === "retailPrice"
            ? this.order
              ? "desc"
              : "asc"
            : "",
        retailEndPrice: this.integralOption[this.integralValue].max,
        retailStartPrice: this.integralOption[this.integralValue].min,
        sortColumn: this.sortColumn,
      };
      if (this.ceCategoryId.length) obj.ceCategoryId = this.ceCategoryId;
      // ceCategoryId: [],

      await this.$API.user
        .IntegralCommodityList({
          object: obj,
          pageNum: this.pageNum,
          pageSize: 4,
        })
        .then((res) => {
          // 加载状态结束
          if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
          // 数据全部加载完成

          res.data.data.forEach((goods) => {
            let img = goods.filePath.split(",");
            if (img[0].endsWith(".mp4")) {
              goods.filePath = img[1];
            } else {
              goods.filePath = img[0];
            }

            goods.tradePrice = Math.trunc(
              preferential(goods, "tradePrice").price
            );
            goods.retailPrice = Math.trunc(
              preferential(goods, "retailPrice").price
            );
            this.goodsList.push(goods);
          });

          if (this.pageNum >= res.data.pages) {
            this.finished = true;
          } else {
            this.pageNum++;
          }

          this.loading = false;

          this.$nextTick(function () {
            this.waterFall();
          });
        })
        .catch((e) => {
          console.log(e.message);
          this.error = true;
          this.loading = false;
        });
    },
    switchCondition(value) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        if (this.sortColumn === value) {
          if (value !== "retailPrice") return;
          this.order = !this.order;
        } else {
          this.order = false;
        }
        this.sortColumn = value;
        this.refresh = true;
        this.onLoad();
      }, 200);
    },
    integralSelection() {
      this.refresh = true;
      this.onLoad();
    },
    goodsBlur() {
      if (this.oldSearchValue != this.searchValue) {
        this.oldSearchValue = this.searchValue;
        this.refresh = true;
        this.onLoad();
      }
    },
    waterFall() {
      // 1. 设置container盒子的宽度
      //      注意：浏览器的可视区域的宽度 / 一个item元素的宽度 = 一行的排列的元素的个数
      let container = document.getElementById("goods_list");
      let item = document.getElementsByClassName("goods_item_box");

      // let item = [];
      // for (const node of container.children) {
      //   if (node.className === "goods_item_box") {
      //     item.push(node);
      //   }
      // }
      if (!item.length) {
        if (container) container.style.height = "auto";
        return;
      }

      //获取元素的宽度(含border，padding)
      let width = item[0].offsetWidth;
      //计算出浏览器窗口的宽度
      let clientWidth = document.documentElement.clientWidth;
      //计算出应该放几列（向下取整）
      // let columnCount = Math.floor(clientWidth / width);
      let columnCount = 2;
      //设置容器（父盒子）的宽度
      // container.style.width = columnCount * width + "px";
      let left;
      let height;
      height = left = container.offsetWidth - width * columnCount; //宽度间隔
      //  height = container.offsetWidth - width * columnCount; //高度间隔

      // 2.设置每一个item元素的排列位置
      //  第一行整体的top值都是0 后面的依次找上一行高度最小的容器，在它下面进行排列
      let hrr = [];
      for (let i = 0; i < item.length; i++) {
        //定位第一行的图片
        if (i < columnCount) {
          item[i].style.top = "0px";
          if (i) {
            item[i].style.left = i * width + left + "px";
          } else {
            item[i].style.left = i * width + "px";
          }
          hrr.push(item[i].offsetHeight + height);
        } else {
          //第一行之后的
          //选择总高度最小的列
          let min = Math.min(...hrr);
          let index = hrr.indexOf(min);
          //将每个元素定位到当前总高度最小的列下
          //index为0在左边 为1在右边 2以此类推
          item[i].style.top = min + "px";
          if (index) {
            item[i].style.left = index * width + left + "px";
          } else {
            item[i].style.left = index * width + "px";
          }
          //当前定位的元素加入该列
          hrr[index] += item[i].offsetHeight + height;
        }
      }
      if (hrr.length < 2) {
        container.style.height = `${hrr[0]}px`;
        return;
      }
      if (hrr[0] >= hrr[1]) {
        container.style.height = `${hrr[0]}px`;
      } else {
        container.style.height = `${hrr[1]}px`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#points_mall {
  background: #f6f7fb;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  ::v-deep .search {
    flex-shrink: 0;
    .van-search {
      transition: 0.2s all;
      padding: 0 15px;
      height: 50px;
      > .van-icon {
        margin-right: 16px;
      }
      .van-field__left-icon .svg-icon {
        color: #a8a8a8;
      }

      .van-search__content {
        background: #fff;
        font-size: 14px;
        .van-field__control {
          font-size: 12px;
        }
      }
    }
  }
  .points_title {
    background: url("../../assets/images/jf_banner.png") no-repeat;
    background-size: cover;
    height: 160px;
    padding: 0 15px 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-shrink: 0;
    h2 {
      padding: 30px 0 38px 0;
      color: #fff;
      font-size: 24px;
      font-weight: 600;
    }
    .points_order {
      padding: 13px 15px;
      background: rgba($color: #fff, $alpha: 0.76);
      border-radius: 10px 10px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-weight: bold;
        color: #333333;
      }
      .svg-icon {
        font-size: 12px;
      }
    }
  }
  .points_content {
    background: #f6f7fb;
    border-radius: 10px 10px 0 0;
    position: relative;
    top: -10px;
    padding: 0 15px;
    .total_points {
      padding: 20px 15px;
      background: #fff;
      border-radius: 0 0 10px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        p {
          line-height: 20px;
        }
        h2 {
          line-height: 45px;
          font-size: 32px;
        }
      }
    }
    ::v-deep .options {
      .van-sticky--fixed {
        top: 50px !important;
        left: 15px;
        right: 15px;
      }
      .van-tabs__wrap {
        height: auto;
        // margin-top: 20px;
        .van-tabs__nav {
          padding: 0;
          padding-top: 20px;
          .van-tab {
            flex: none;
            font-size: 16px;
            color: #333333;
            padding: 5px 10px;
            border-radius: 16px;
            min-width: 72px;
            transition: 0.2s all;
            margin-right: 4px;
          }
          .van-tab--active {
            background: #ffffff;
          }
          .van-tabs__line {
            display: none;
          }
        }
      }
      .van-dropdown-menu {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        background: #f6f7fb;
        button {
          border: none;
          color: #666;
          background: #f6f7fb;
          line-height: 20px;
          margin-right: 50px;
          position: relative;
          span {
            position: absolute;
            display: flex;
            flex-direction: column;
            right: -10px;
            top: 50%;
            margin-top: -7px;
            .svg-icon {
              font-size: 7px;
            }
            .svg-icon:nth-child(1) {
              transform: rotate(-90deg);
            }
            .svg-icon:nth-child(2) {
              transform: rotate(90deg);
            }
          }
        }
        .van-dropdown-menu__bar {
          height: 60px;
          background: #f6f7fb;
          box-shadow: none;
          .van-ellipsis {
            color: #666;
          }
          .van-overlay {
            background: rgba($color: #000000, $alpha: 0.3);
          }
        }
        .van-dropdown-item {
          .van-overlay {
            background: rgba($color: #333, $alpha: 0.48);
          }
          .van-popup {
            border-radius: 0px 0px 20px 20px;
          }
        }
      }
    }
    #goods_list {
      position: relative;
      .none_goods_tips {
        color: #999999;
        text-align: center;
        margin: 30% 0;
      }
      .goods_item_box {
        position: absolute;
        width: 48%;
        background: #fff;
        border-radius: 10px;
        .img_box {
          position: relative;
          width: 100%;
          height: 0;
          padding-top: 100%;
          .goods_img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px 10px 0 0;
            object-fit: contain;
          }
        }

        .goods_details {
          padding: 12px 8px;
          .name {
            font-weight: bold;
            color: #333333;
            margin-bottom: 15px;
          }
          .integral {
            margin-bottom: 17px;
            font-size: 16px;
            font-weight: bold;
            color: #fb4e10;
            line-height: 22px;
            display: flex;
            align-items: center;
            img {
              width: 16px;
              margin-right: 8px;
            }
          }
          .van-button {
            height: 34px;
            width: 90px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
</style>
